<template>
  <div class="text-center" :class="cssClass">
    <span class="spinner-border spinner-border-sm align-middle"></span>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: ['color', 'size'],
  computed: {
    cssClass () {
      return [
        `text-${this.color}`,
        `size-${this.size}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.size-big {
  span {
    width: 40px;
    height: 40px;
  }
}
.size-regular {
  font-size: $font_size_regular;
}
.size-medium {
  font-size: $font_size_medium;
}
</style>
