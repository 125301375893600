<template>
  <b-modal
    :id="`update-rule-${rule.id}-google-id`"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
    @shown="shown"
  >
    <template #modal-header="{ close }">
      <div></div>
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
    </template>

    <div class="text-center">
      <h2>Adicionar ID do Pixel</h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <form class="default-form text-start" @submit.prevent="submit">
        <div class="form-group mb-3">
          <label class="form-label">Nome da regra</label><br/>
          <span>{{ rule.name }}</span>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">ID da regra do pixel</label>
          <input type="text" class="form-control" placeholder="ID" v-model.trim="$v.form.platform_id.$model" />
        </div>
        <template v-if="platform === 'GOOGLE'">
          <div class="form-group mb-3">
            <label class="form-label">ID do Floodlight Group</label>
            <input type="text" class="form-control" placeholder="ID" v-model.trim="form.pixel_google_platform_id" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Activity Tag String</label>
            <input type="text" class="form-control" placeholder="ID" v-model.trim="form.google_activity_tag" />
          </div>
        </template>
        <div class="text-center">
          <Button
            type="submit"
            class="btn btn-primary mt-4"
            :disabled="$v.$invalid"
            :loading="loader"
          >
            Adicionar
          </Button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>

import PixelRulesService from '@/modules/pixels/services/pixel-rules-service'
import { parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  props: ['platform', 'rule'],
  name: '',
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error',
      form: {
        google_activity_tag: '',
        pixel_google_platform_id: '',
        platform_id: '',
        platform: ''
      }
    }
  },
  validations () {
    return {
      form: {
        platform_id: { required },
        platform: { required }
      }
    }
  },
  methods: {
    /**
     * Hidden
     */
    hidden () {
      // this.form.code = ''
    },
    /**
     * Shown
     */
    shown () {
      this.form.platform = this.platform
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        const rule = await PixelRulesService.updatePixelRule(this.rule.id, {
          platformId: this.form.platform_id,
          platform: this.form.platform,
          googleActivityTag: this.form.google_activity_tag,
          pixelGooglePlatformId: this.form.pixel_google_platform_id
        })
        this.$bvModal.hide(`update-rule-${this.rule.id}-google-id`)
        this.$emit('pixel-rule-updated', rule)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
