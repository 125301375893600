<template>

  <button
    :type="btnType"
    class="btn flex-shrink-0"
    @click="$emit('click', $event)"
    :data-kt-indicator="loading ? 'on' : 'off'" :disabled="loading"
  >
    <!--begin::Indicator label-->
    <span class="indicator-label" data-kt-translate="sign-in-submit"><slot /></span>
    <!--end::Indicator label-->
    <!--begin::Indicator progress-->
    <span class="indicator-progress">
      <span data-kt-translate="general-progress">Aguarde...</span>
      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>
    <!--end::Indicator progress-->
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['type', 'loading'],
  computed: {
    btnType () {
      return this.type ? this.type : 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
