import axios from '@/plugins/axios'

/**
 * Get pixel rules
 */
const getPixelRules = async ({
  // eslint-disable-next-line camelcase
  advertiser_id = null,
  status = null,
  platform = null,
  objective = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (advertiser_id) params.advertiser_id = advertiser_id
    if (status) params.status = status
    if (platform) params.platform = platform
    if (objective) params.objective = objective
    const response = await axios.get('admin/pixel-rules', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update pixel rule
 */
const updatePixelRule = async (id, {
  googleActivityTag = null,
  pixelGooglePlatformId = null,
  platformId = null,
  platform = null
}) => {
  try {
    const form = {
      _method: 'PUT'
    }
    if (platformId) form.platform_id = platformId
    if (platform) form.platform = platform
    if (googleActivityTag) form.google_activity_tag = googleActivityTag
    if (pixelGooglePlatformId) form.pixel_google_platform_id = pixelGooglePlatformId
    const response = await axios.post(`admin/pixel-rules/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getPixelRules,
  updatePixelRule
}
