<template>
  <div class="local-alert">
    <BaseAlert v-if="localContent"
      :type="type"
      :content="localContent"
      @dismiss-alert="dismiss"
    />
  </div>
</template>

<script>

import BaseAlert from './BaseAlert'

export default {
  name: 'AlertWraper',
  components: {
    BaseAlert
  },
  props: ['content', 'type'],
  data () {
    return {
      localContent: this.content && Array.isArray(this.content) ? [...this.content] : this.content
    }
  },
  watch: {
    content (newContent, oldContent) {
      this.localContent = Array.isArray(newContent) ? [...newContent] : newContent
    }
  },
  methods: {
    dismiss () {
      this.localContent = null
      this.$emit('dismiss-alert')
    }
  }
}
</script>
