<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ title }}</h1>
        <!--end::Title-->
      </div>
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && rules.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else>
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->
              <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-100px">Anunciante</th>
                <th class="min-w-70px">Nome do Pixel</th>
                <th class="min-w-100px">Nome da Regra</th>
                <th class="min-w-100px">Objetivo</th>
                <th class="min-w-100px">Nome do evento</th>
                <th class="min-w-100px">Link</th>
                <th class="min-w-100px">Ações</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="fw-semibold text-gray-600">
              <!--begin::Table row-->
              <tr v-for="(rule, index) in rules" :key="index">
                <td>
                  <span>{{ rule.pixel.advertiser.name }}</span>
                </td>
                <td class=" pe-0">
                  <span>{{ rule.pixel.name }}</span>
                </td>
                <td class=" pe-0">
                  <span>{{ rule.name }}
                    <template v-if="platform === 'YAHOO'">
                      {{ rule.yahoo_id ? `(ID #${rule.yahoo_id})` : '' }}
                    </template>
                    <template v-if="platform === 'GOOGLE'">
                      {{ rule.google_id ? `(ID #${rule.google_id})` : '' }}
                    </template>
                  </span>
                </td>
                <td class=" pe-0">
                  <span>{{ rule.objective }}</span>
                </td>
                <td class=" pe-0">
                  <span>{{ rule.event_name }}</span>
                </td>
                <td class=" pe-0">
                  <span>
                    <a
                      :href="`https://displayvideo.google.com/ng_nav/p/660702863/a/${rule.pixel.advertiser.google_id}/fl/events/new`"
                      target="_blank"
                      v-if="platform === 'GOOGLE'"
                    >
                      Clique aqui
                    </a>
                    <a
                      :href="`https://displayvideo.google.com/ng_nav/p/660702863/a/${rule.pixel.advertiser.google_id}/fl/events/new`"
                      target="_blank"
                      v-else-if="platform === 'YAHOO'"
                    >
                      Clique aqui
                    </a>
                  </span>
                </td>
                <td>
                  <Button
                    class="btn-primary btn-sm"
                    v-b-modal="`update-rule-${rule.id}-google-id`"
                  >
                    <i class="fa fa-pencil p-0"></i>
                  </Button>
                  <UpdateRuleIdModal
                    :rule="rule"
                    :platform="platform"
                    @pixel-rule-updated="handleUpdated"
                  />
                </td>
              </tr>
              <!--end::Table row-->
            </tbody>
            <!--end::Table body-->
          </table>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import PixelRulesService from '@/modules/pixels/services/pixel-rules-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import UpdateRuleIdModal from '@/components/pixels/UpdateRuleIdModal'

export default {
  components: {
    Button,
    Loader,
    MainLayout,
    UpdateRuleIdModal
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
  data () {
    return {
      isLoadMoreVisible: false,
      loader: false,
      page: 1,
      rules: []
    }
  },
  created () {
    this.getPixelRules()
  },
  watch: {
    $route (to, from) {
      this.reset()
    }
  },
  computed: {
    /**
     * Current platform
     */
    platform () {
      return this.$route.query.platform
    },
    /**
     * Page title
     */
    title () {
      if (this.platform === 'GOOGLE') return 'Pixels Pendentes - DV360'
      else return 'Pixels Pendentes - YAHOO'
    }
  },
  methods: {
    /**
     * Get pixel rules
     */
    async getPixelRules () {
      this.loader = true
      try {
        const rules = await PixelRulesService.getPixelRules({
          status: 'PENDING',
          platform: this.platform,
          objective: this.$route.query.objective,
          page: this.page
        })
        this.rules = [...this.rules, ...rules.data]
        this.isLoadMoreVisible = rules.meta.current_page !== rules.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle updated
     */
    handleUpdated (rule) {
      const index = this.rules.findIndex(item => {
        return item.id === rule.id
      })
      if (index >= 0) this.rules.splice(index, 1, rule)
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getPixelRules()
    },
    /**
     * Reset
     */
    reset () {
      this.page = 1
      this.rules = []
      this.getPixelRules()
    }
  }
}
</script>
